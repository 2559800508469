import React from 'react'
import styled from 'styled-components'

import SEO from '../components/seo'

import H from '../components/styled/Heading'
import P from '../components/styled/Paragraph'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  align-items: center;
`

const Header = styled(H)`
  padding-top: 161px;

  @media only screen and (min-width: 1024px) {
    padding-top: 0;
    padding-left: 6px;
    margin-bottom: 76px;
  }
`
const Text = styled(P)`
  font-size: 26px;
  letter-spacing: 2px;
  line-height: 30px;
  max-width: 1330px;
  text-align: center;
  padding: 0 15px;
`

const Discovered = styled.img`
  width: 100%;

  @media only screen and (min-width: 1024px) {
    max-width: 990px;
  }
`

const NotFoundPage = () => (
  <div>
    <Wrapper>
      <SEO title="404" />
      <Header size={1}>404</Header>
      <Text uppercase>
        The page you were looking for doesn't exist. You may have mistyped the
        address or the page may have moved.
      </Text>
      <Discovered src={'/discovered.svg'} />
    </Wrapper>
  </div>
)

export default NotFoundPage
